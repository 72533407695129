body {
  overflow-x: hidden;
}
body.show {
  overflow-x: auto;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #fff;
  color: #fff;
  overflow: hidden;
  font-family: sans-serif;
}
.preloader.hide {
  opacity: 0;
  transition: 1s;
  transition-delay: 0.5s;
  pointer-events: none;
}
.preloader.remove {
  display: none;
}
.preloader__loader {
  padding-top: 10%;
  font-size: 200px;
  text-align: center;
}
@media (max-width: 700px) {
  .preloader__loader {
    font-size: 60px;
  }
}
.loaderSpin {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #61bf1a;
  -webkit-animation: loaderSpin 2s linear infinite;
  animation: loaderSpin 2s linear infinite;
}
.loaderSpin:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #087fc4;
  -webkit-animation: loaderSpin 2.5s linear infinite;
  animation: loaderSpin 2.5s linear infinite;
}
.loaderSpin:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #00bce7;
  border-bottom-color: #fecc00;
  -webkit-animation: loaderSpin 1.5s linear infinite;
  animation: loaderSpin 1.5s linear infinite;
}
@-webkit-keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
